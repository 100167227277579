import React from 'react'

interface PrivacyStatementProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PrivacyStatement
  location: any
}

const PrivacyStatement: React.FC<PrivacyStatementProps> = ({ location }) => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          {location.hostname !== 'localhost' ? (
            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/6038fa4b-9bf3-44c4-91bf-b612879b4769/cd.js"
              type="text/javascript"
              async
            />
          ) : (
            <h2>Privacyverklaring niet beschikbaar in lokale omgeving...</h2>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default PrivacyStatement
